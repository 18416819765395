import React from 'react';
import '../css/footer.css';

const myFooter = ()=>{
    return (
        <div className="footer">
            <span className="text">Interested in knowing more? Let's connect on  </span>
            <span className="item">
                <a href="https://github.com/Xilaizhang" target="_blank" rel='noopener noreferrer' style={{color:'black'}}>
                    <i className="github icon large"></i>
                </a>
                <a href="https://www.linkedin.com/in/xilai-zhang-268758171/" target="_blank" rel='noopener noreferrer' style={{color:'black'}}>
                    <i className="linkedin icon large"></i>
                </a>
                <a href="https://github.com/XilaiZhang/resume/blob/main/09_21_resume_xilaizhang.pdf" target="_blank" rel='noopener noreferrer' style={{color:'black'}}>
                    <i className="file alternate outline icon large"></i>
                </a>
            </span>
        </div>
                    
    );
}

export default myFooter;